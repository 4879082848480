.breadcrumb {
  align-items: center;
  display: flex;
  font-size: 14px;
  gap: 4px;
  list-style: none;
  margin: 0 0 20px;
  padding: 0;

  li {
    align-items: center;
    display: flex;
    gap: 4px;
  }

  a {
    color: #0E5498;
    line-height: 12px;
    text-decoration: none;
  }
}