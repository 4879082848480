.footer {
    border-top: solid 1px #ddd;
    font-size: 12px;
    margin: 80px 0 0;
    padding: 40px 0;
    text-align: center;
}

    .login-button {
        display: block;
        margin: 10px 0 0;
    }