.aside {
    background-color: $primary;
    border: solid 2px #F3F7FA;
    color: white;
    height: calc(100vh - 206px);
    position: sticky;
    text-align: center;
    top: 10px;
    width: 400px;

    &__title {
        font-size: 30px;
        font-style: italic;
        margin: 0;
        padding: 30px 0;
        text-transform: uppercase;
    }

    &__item {
        color: white;
        display: block;
        font-size: 26px;
        padding: 20px 0;
        text-decoration: none;

        &.mod-active {
            background: white;
            color: $primary;
        }

        &-img {
            height: 68px;
            width: auto;
        }
    }
}