.header {
  align-items: center;
  display: flex;
  
  &__logo {
    height: auto;
    margin: 30px 85px;
    width: 230px;

    &.mod-disconnected {
      width: 600px;
    }
  }

  &__nav {
    align-items: center;
    display: flex;
    gap: 120px;
    margin: 0 60px;
    width: 100%;

    &-link {
      color: $primary;
      display: flex;
      font-size: 20px;
      font-weight: 800;
      text-decoration: none;
    
      &.mod-active {
        text-decoration: underline;
      }

      &.button {
        color: white;
        font-weight: 400;
      }
    }

    &-right {
      align-items: center;
      margin-left: auto;
      display: flex;
      gap: 20px;
    }
  }

  &.mod-not-logged {
    justify-content: center;
  }
}