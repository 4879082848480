.dd-selected {
  color: black;
  background: white;
  align-items: center;
  display: flex!important;
  gap: 20px;
  padding: 15px 30px!important;
  width: 100%!important;
}

.dd-container,
.dd-options,
.dd-select {
  width: 100%!important;
}

.dd-option {
  align-items: center;
  display: flex!important;
  gap: 20px;
}

.dd-option-text {
  line-height: 32px!important;
}