.wrapper {
    display: flex;
    gap: 60px;

    &__content {
        align-items: self-start;
        display: flex;
        flex-direction: column;
        width: calc(100% - 500px);

        &.mod-center {
            align-items: center;
            margin: auto;
        }

        &.mod-line {
            flex-direction: row;
            gap: 60px;
            justify-content: space-around;
        }
        
        > a {
            display: inline-block;
        }

        &-left {
            width: 50%;
            text-align: center;
        }

        &-right {
            margin: 2px;
            width: 50%;
        }

        &-readonly {
            font-size: 18px;
            margin: 20px 0;
        }

        &-table {
            align-self: stretch;
        }

        &-field {
            background: #eeeeee;
            padding: 8px 15px;
            margin: 0 0 10px;
        }

        &-help {
            margin: -13px 0 0;
            font-size: 13px;
            font-style: italic;
            opacity: 0.6;
        }

        &-formats {
            display: flex;
            justify-content: space-between;
            margin: 0 0 30px;
        }

        &-format {
            strong {
                display: block;
                margin: 0 0 10px;
                text-align: center;
            }

            &-download {
                background: #0E5498;
                border: none;
                color: white;
                cursor: pointer;
                display: inline-block;
                font-size: 18px;
                padding: 10px 30px;
                text-decoration: none;
                text-align: center;
                display: block;
                margin: 20px;
            }
        }
    }

    &__validate {
        margin: 20px 0 0;
        text-align: right;
        display: flex;

        * {
            width: 100%;
        }
    }

    &__download {
        display: block;

        * {
            width: 100%;
        }

        &.mod-hide {
            display: none;
        }
    }

    &__iframe {
        align-items: center;
        border: solid 1px #ddd;
        box-sizing: border-box;
        display: flex;
        height: 470px;
        margin: auto;
        padding: 10px 25px;
        width: 470px;

        iframe {
            border: none;
            height: 420px;
            width: 420px;
        }
    }
}