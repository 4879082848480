.login {
  align-items: center;
  box-shadow: 0px 0px 25px -3px rgba(0,0,0,0.15);
  display: flex;
  flex-direction: column;
  font-size: 20px;
  gap: 30px;
  max-width: 500px;
  margin: auto;
  padding: 60px;

  &.mod-hide {
    display: none;
  }

  &__description {
    text-align: center;
    line-height: 30px;
  }

  &__title {
    color: $primary;
    font-size: 26px;
    font-style: italic;
    margin: 0;
    text-transform: uppercase;
  }

  &__email {
    border: solid 2px #ddd;
    font-size: 20px;
    padding: 10px 20px;
    width: 100%;
  }

  &__submit {
    background: $primary;
    border: none;
    color: white;
    cursor: pointer;
    font-size: 20px;
    padding: 10px 30px;
  }
}