body {
    font-family: 'Open Sans';
}

h1, h2, h3, h4, h5, h6 {
    color: $primary;
    text-transform: uppercase;

    &.mod-white {
        color: white;
    }
}

h2 {
    font-size: 30px;
    font-style: italic;
}

button, .button {
    background: $primary;
    border: none;
    color: white;
    cursor: pointer;
    display: inline-block;
    font-size: 18px;
    padding: 10px 30px;
    text-decoration: none;
}

input {
    border: solid 1px #ddd;
    box-sizing: border-box;
    font-size: 20px;
    margin: 0 0 20px;
    padding: 10px 25px;
    width: 100%;
}

label {
    display: block;
    margin: 10px 0;
    opacity: 0.6;
}