.dataTables_wrapper {
  box-shadow: 0px 0px 25px -3px rgba(0,0,0,0.15);
  border-radius: 10px;
  margin: 20px 0 0;
  padding: 20px 30px;

  th {
    text-align: left;
  }

  tr.odd { background-color: #f7f7f7; }
  tr.even { background-color: white; }

  table.dataTable tbody td {
    padding: 8px 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 500px;

    a, a:hover, a:visited {
      color: $primary;
      text-decoration: none;
    }
  }
}