.field-brand {
  display: flex;
}

.label-select {
  align-items: center;
  border: solid 1px #ddd;
  color: #5d5d5d;
  display: flex;
  font-size: 20px;
  margin: initial;
  padding: 10px 25px;
}

.input-select {
  background: none;
  border: solid 1px #ddd;
  box-sizing: border-box;
  padding: 12px 25px;
  font-size: 20px;
  width: 100%;
}